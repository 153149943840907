/* CSS Document */


h1, h2, h3, label, input[type="submit"]{
	color: #ffffff;
}

fieldset{
	padding: 10px;
	margin: 10px;
	border:1px dashed #F2E527;
	font-weight: bold;
}

legend{
	margin-left: 35px;
}
legend h2{
	margin: 0px 5px;
}
form{	
	padding: 4px;
}
form label, form input{
	display: block;
	margin: 4px;
}

form input{
	width: 90%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
}

form select{
	width: 100%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
  
}


form label{
	width: 90%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
}

form input[type="submit"]{	
	margin: 20px auto;
	background-color: #1976d2;
	border:none;
	border-radius: 2px 2px 2px 2px;
}

form input[type="submit"]:hover{
	background-color: #74b4f3;
}