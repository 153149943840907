/* CSS Document */
*{
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

html, body{
	height: 100%;
	
}
/* #1A202C */
body{
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

header, nav, main, footer{
	width: 95%;
	margin: 2px auto;
}

header{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 110px;
	border-bottom:1px dashed #5471B4;
}

footer{
	border-top:1px dashed #5471B4;
}

.btHome{
	margin-right: 5px !important;
}