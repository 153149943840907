
h1, h2, h3, label, input[type="submit"]{
	color: #ffffff;
}

body {
	background-color: #1A202C;
}

fieldset{
	padding: 10px;
	margin: 10px;
	border:1px dashed #F2E527;
	font-weight: bold;
}

legend{
	text-align: center;
}
legend h2{
	margin: 0px 25px;
	color: #fff;
}
.form{	
	padding: 4px;
	background: none !important;
}
form label, form input{
	display: block;
	margin: 4px;
}

.fundo{
	background: url('https://cachoeiradacapivara.com.br/wp-content/uploads/2021/08/primeiro01.webp');
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}

.input{
	width: 80% !important;
    margin: 25px auto !important;
    height: 45px !important;
    border-radius: 40px !important;
    font-size: 16px !important;
    padding: 0 15px !important;
}


.logo{
	text-align: center;
	padding: 20px 0;
}

.logo>img{
	width: 15em;
}

form select{
	width: 100%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
  
}


form label{
	width: 90%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
}

form input[type="submit"]{	
	padding: 0 20px 0 20px;
	margin: 20px auto;
	background-color: #1976d2;
	border:none;
	border-radius: 5px;
	font-size: 16px;
    font-weight: bold;
	cursor: pointer;
}

.inputSubmit{
	border-radius: 40px !important;
}

