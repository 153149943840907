
 h3, label, input[type="submit"]{
	color: #ffffff;
}
h1,h2{
	color:#1A202C;
}

fieldset{
	padding: 10px;
	margin: 10px;
	border:2px dashed #F2E527;
	font-weight: bold;
}

legend{
	margin-left: 35px;
}
legend h2{
	margin: 0px 5px;
}
form{	
	padding: 4px;
	background-color: #1A202C;
}
form label, form input{
	display: block;
	margin: 4px;
}

form input{
	width: 90%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
}

.css-dmmspl-MuiFormGroup-root{
	padding-left: 5%;
}

form select{
	width: 100%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;
  
}


form label{
	width: 90%;
	margin: 2px auto;
	height: 35px;
	font-size: 26px;

}

form input[type="submit"]{	
	width: 200px;
	margin: 20px auto;
	background-color: #1976d2;
	border:none;
	border-radius: 2px 2px 2px 2px;
}

