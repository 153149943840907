
  
  .inputLogin{
    background-color: rgb(247, 247, 247);
  }
  
  .titulo{
    padding-left: 5%;
  }
  .tituloLogin{
    padding-bottom: 40px;
  }

  .campo{
    font-size: 18px;
  }

  @media all and (min-width: 480px) {
   
    
      .Login form {
        max-width: 320px;
        background-color: #ffffff;
        border-radius: 20px;
      }
      
    }

    .btn_login{	
      width: 200px;
      margin: 20px auto;
      background-color: #1976d2;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      padding: 0 20px 0 20px;
      font-weight: bold;
      cursor: pointer;
      height: 35px;
      color:#fff
    }